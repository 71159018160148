import { type FC, useEffect, useMemo } from 'react';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useVaultContentByFolderPosition } from '../../hooks/useVaultContent';
import { LoginStatus } from '../../types/authTypes';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { VaultUploadPromptView } from '../vault/VaultUploadPromptView';
import { VaultItemsSkeleton } from '../vault/items/ItemSkeleton';
import { VaultItem } from '../vault/items/VaultItem';
import { EmptyVaultPreview } from './EmptyVaultPreview';

export const VaultContents: FC<{
  isOwner: boolean;
  vaultId: string;
  artistLinkValue: string;
  folderId: string | null;
}> = ({ isOwner, vaultId, artistLinkValue, folderId }) => {
  const { loginStatus } = useAuthContext();
  const { isVaultCustomizeOpen } = useMenuContainer();

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  const { orderedList, isInitialLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useVaultContentByFolderPosition({
      vaultId,
      folderId,
    });

  const allImageAndVideos = useMemo(
    () =>
      orderedList.filter(
        node => node.__typename === 'VaultImage' || node.__typename === 'VaultVideo',
      ),
    [orderedList],
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView, isFetchingNextPage]);

  const LoadingFooter = React.useCallback(
    () => (isFetchingNextPage || isInitialLoading ? <VaultItemsSkeleton itemCount={3} /> : null),
    [isFetchingNextPage, isInitialLoading],
  );

  return (
    <View className="mb-4 w-full">
      {isOwner &&
        loginStatus === LoginStatus.LOGGED_IN &&
        !isInitialLoading &&
        orderedList.length === 0 &&
        (isVaultCustomizeOpen ? (
          <EmptyVaultPreview artistHandle={artistLinkValue} />
        ) : (
          <VaultUploadPromptView
            vaultId={vaultId}
            artistLinkValue={artistLinkValue}
            folderId={folderId}
          />
        ))}
      <View className="grid flex-1 grid-cols-2 items-start gap-x-4 gap-y-[18px] overflow-y-scroll pt-4 scrollbar-none md2:grid-cols-3">
        {orderedList.map((item, i) => {
          return (
            <VaultItem
              isOwner={isOwner}
              key={item.id}
              item={item}
              allImageAndVideos={allImageAndVideos}
              vaultId={vaultId}
              containerRef={i === orderedList.length - 1 ? ref : undefined}
              artistHandle={artistLinkValue}
            />
          );
        })}

        <LoadingFooter />
      </View>
    </View>
  );
};

export const VaultContentSkeleton: FC = () => {
  return (
    <View className="no-scrollbar mt-24 flex min-h-full w-full flex-1 flex-col items-center overflow-y-scroll">
      <View className="relative top-0 z-base aspect-square w-full overflow-clip" />
      <View className="z-above1 -mt-[312px] flex w-full flex-col md2:-mt-[400px]">
        <View className="flex flex-col items-center">
          <View className="mb-[20px] flex-col md2:mb-[13px]">
            <LoadingSkeleton className="mb-2 h-[30px] w-[250px] bg-vault_text/10" />
          </View>
          <View className="my-4 flex w-full items-center px-2">
            <View className="box-border grid w-full flex-1 grid-cols-2 flex-col items-center gap-x-4 gap-y-[18px] px-6 md2:grid-cols-3 md2:px-12">
              <VaultItemsSkeleton itemCount={3} />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
