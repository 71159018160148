import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import * as z from 'zod';
import { faSearch } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faCircleXmark } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { View } from '../components/common/View';

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

if (!GOOGLE_API_KEY) {
  throw new Error('VITE_GOOGLE_MAPS_API_KEY is not defined');
}

const GeocoderResultSchema = z.object({
  label: z.string(),
  value: z
    .object({
      place_id: z.string(),
    })
    .passthrough(),
});

export function useSingleLocationSelect({
  initialLocation,
  placeholder,
  className,
  setIsKeyboardOpen,
  onLocationChange,
  error,
  onBlur,
}: {
  initialLocation: {
    placeId: string;
    label: string;
  } | null;
  placeholder: string;
  className?: string;
  setIsKeyboardOpen: ((isOpen: boolean) => void) | null;
  onLocationChange?: (value: typeof GeocoderResultSchema._output | null) => void;
  onBlur?: () => void;
  error?: boolean;
}) {
  const initialLocationPlaceId = initialLocation?.placeId;
  const initialLocationLabel = initialLocation?.label;
  const [focus, setFocus] = useState(false);

  const [locationValue, setLocationValue] = useState<typeof GeocoderResultSchema._output | null>(
    () =>
      initialLocationPlaceId && initialLocationLabel
        ? { label: initialLocationLabel, value: { place_id: initialLocationPlaceId } }
        : null,
  );

  useEffect(() => {
    setLocationValue(prev =>
      prev?.value.place_id === initialLocationPlaceId && prev?.label === initialLocationLabel
        ? prev
        : initialLocationPlaceId && initialLocationLabel
          ? { label: initialLocationLabel, value: { place_id: initialLocationPlaceId } }
          : null,
    );
  }, [initialLocationLabel, initialLocationPlaceId]);

  const locationSelect = (
    <View className="flex h-[min(70vh,400px)] w-full flex-col">
      <View className="flex w-[95%] flex-row items-center gap-2 rounded-lg bg-base800 p-2 pl-4">
        <FontAwesomeIcon icon={faSearch} className="text-base500" />
        <GooglePlacesAutocomplete
          apiKey={GOOGLE_API_KEY}
          apiOptions={{
            language: 'en',
          }}
          autocompletionRequest={{
            types: ['locality'],
          }}
          selectProps={{
            value: locationValue,
            className: clsx('w-full text-white', className),
            onChange(value) {
              const parsedValue = value && GeocoderResultSchema.parse(value);
              // We need zod parsing due to missing proper typing
              setLocationValue(parsedValue);
              onLocationChange?.(parsedValue);
            },
            isClearable: true,
            placeholder,
            noOptionsMessage({ inputValue }) {
              return inputValue.trim().length ? 'Location could not be found' : '';
            },
            autoFocus: true,
            classNames: {
              dropdownIndicator: () => '!hidden',
              indicatorSeparator: () => '!hidden',
              control: () =>
                clsx(
                  '!shadow-none !cursor-pointer !border-0 !border-base800',
                  error ? '!bg-destructive50' : '!bg-transparent',
                  focus && (error ? '!shadow-[0_0_0_1px_#EF4444]' : '!shadow-[0_0_0_1px_#1F1F1F]'),
                ),
              input: () => '!cursor-text !text-white',
              option: () => '!cursor-pointer !bg-transparent location-select-option',
              valueContainer: () => clsx(error && '!bg-destructive50', '!text-white'),
              menuList: () => '!bg-transparent flex flex-col',

              singleValue: () => '!text-white !bg-transparent',
              container: () => '!bg-transparent',
              menuPortal: () => '!bg-transparent',
              menu: () => '!bg-transparent location-select-menu',
            },
            onFocus: () => {
              setIsKeyboardOpen?.(true);
              setFocus(true);
            },
            onBlur: () => {
              setIsKeyboardOpen?.(false);
              setFocus(false);
              onBlur?.();
            },

            components: {
              ClearIndicator() {
                return (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="mr-2 cursor-pointer text-[20px]"
                    onClick={() => {
                      setLocationValue(null);
                      onLocationChange?.(null);
                    }}
                  />
                );
              },
            },
          }}
        />
      </View>
    </View>
  );

  return {
    locationValue,
    locationSelect,
    setLocationValue,
  };
}
