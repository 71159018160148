import { useSingleLocationSelect } from '../../hooks/useSelectLocation';
import type { SearchGoogleLocationBottomsheetProps } from '../../types/bottomsheetTypes';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function SearchGoogleLocationBottomSheet({
  onLocationChange,
  initialLocation,
}: SearchGoogleLocationBottomsheetProps) {
  const { locationSelect } = useSingleLocationSelect({
    initialLocation: initialLocation
      ? {
          placeId: initialLocation.placeId,
          label: initialLocation.label,
        }
      : null,
    placeholder: 'Set location',
    setIsKeyboardOpen: null,
    onLocationChange(value) {
      onLocationChange(value);
    },
  });
  return (
    <View className="flex w-full flex-col items-center justify-center gap-8 pb-1">
      <Text className="font-title text-base-xl font-medium">Set location</Text>
      {locationSelect}
    </View>
  );
}
